import React from "react"

export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto text-center py-48 px-4 sm:px-6 lg:py-64 lg:px-48">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">
            Tiedustele saatavutta, suosimme sähköpostin käyttöä.
          </span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="ml-3 inline-flex">
            <a
              href="tel:+358 40 6647263"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            >
              Puhelimitse: +358 40 6647263
            </a>
          </div>

          <div className="ml-3 inline-flex">
            <a
              href="mailto:info@peltotie34.fi"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            >
              Sähköposti: info@peltotie34.fi
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
