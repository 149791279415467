import React from "react"

const faqs = [
  {
    id: 2,
    question: "Pitopalvelu ja tarjoilu",
    answer:
      "Tilaisuuteen saa tuoda omat ruoat ja juomat tai käyttää pitopalvelua. Alkoholia saa tarjoilla, mutta alkoholin myynti on kielletty.",
  },
  {
    id: 3,
    question: "Juhlien jälkeen",
    answer:
      "Kun juhlat on juhlittu ja kaikki tilat siivottu, lukitse ovet ja palauta avaimet sopimuksen mukaan.",
  },
  {
    id: 4,
    question: "Kynttilöiden polttaminen",
    answer: "Kynttilöitä ei saa rakennuksien sisätiloissa polttaa.",
  },
  // More questions...
]

export default function Example() {
  return (
    <section aria-labelledby="faq-heading" className="bg-white">
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="max-w-2xl lg:mx-auto lg:text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Yleiset säännöt
            </h2>
          </div>
          <div className="mt-8">
            <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
              {faqs.map(faq => (
                <div key={faq.id}>
                  <dt className="font-semibold text-3xl text-gray-900">
                    {faq.question}
                  </dt>
                  <dd className="mt-3 text-xl text-gray-700">{faq.answer}</dd>
                  <br />
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  )
}
