import React from "react"
import { StaticImage } from "gatsby-plugin-image"
const features = [
  {
    name: "Päärakennus",
    description: "Tilat 20-50 henkilölle",
    description2: "5 huonetta, iso keittiö, kuistiterassi, 2 WC",
  },
  {
    name: "Puimala",
    description: "Tilat jopa 100 henkilölle. Sopii hyvin tansseille.",
    description2:
      "Ylä- ja talatila. Esteetön",
  },
  {
    name: "Pehtoorin tupa",
    description: "Majoitustilat 3:lle henkilölle",
    description2: "2h, keittiö ja WC",
  },
  {
    name: "Puulämmitteinen sauna",
    description: "Joen rannalla",
    description2: "Lauteilla tilat 8:lle",
  },
  {
    name: "Grillikota",
    description: "Keskellä tulisija, ympärillä istuimet",
    description2: "",
  },
  {
    name: "Paikoitustilat",
    description: "Suuri ilmainen kartanon oma paikoitustila.",
    description2:
      "Lisäksi iso asvaltoitu kenttä jota voidaan käyttää myös paikoitustilana.",
  },
]

export default function Features() {
  return (
    <div>
      <div className="bg-white">
        <div aria-hidden="true" className="relative">
          <StaticImage
            src="../images/maatila_ulkoa_kauempana_cropped.jpg"
            alt="gatsby astronaut"
            className="w-full object-center object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-white via-transparent to-white" />
        </div>

        <div className="absolute top-2 md:top-12 bottom-64 left-2 md:left-12">
          <h1 className="text-gray-700 block text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold">
            Jokirannan Kartano
          </h1>
        </div>

        <div className="absolute right-2 md:right-12">
          <div className="mx-auto text-right max-w-2xl md:max-w-3xl xl:max-w-4xl 2xl:max-w-screen-lg">
            <h2 className="text-xl sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-extrabold text-gray-900">
              <span>Elämäsi kaikki juhlat maalla –</span>
              <br />
              <span>Jokirannan Kartanossa, Sipoossa</span>
            </h2>
            <p className="text-md sm:text-xl 2xl:text-3xl mt-4 sm:mt-1 text-gray-700">
              Jokirannan kartano sijaitsee noin 23 km Helsingin
              rautatieasemalta, Lahden moottoritien ja vanhan Lahdentien
              välissä, aivan Sipoon ja Vantaan rajalla.
            </p>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <div className="relative max-w-7xl mx-auto">
          <div className="max-w-2xl mx-auto text-center lg:max-w-4xl"></div>

          <dl className="mt-60 md:mt28 ml-4 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
            {features.map(feature => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-bold text-3xl text-gray-900">
                  {feature.name}
                </dt>
                <dd className="mt-2 text text-gray-700 text-xl">
                  {feature.description}
                </dd>
                <dd className="mt-2 text text-gray-700 text-xl">
                  {feature.description2}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
