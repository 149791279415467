import React from "react"
import "./hinnat.css"

export default function Example() {
  return (
    <div>
      <section aria-labelledby="faq-heading" className="bg-white">
        <div className="bg-white">
          <br />
          <div className="max-w-2xl lg:mx-auto lg:text-center mx-auto">
            <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
              Hinnat 2024
            </h2>
          <br />
          <br />
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Päärakennus
            </h2>
            <br />
            <br />
          </div>
        </div>
        <div className="hintaContainer max-w-2xl text-left mx-auto text-gray-700 text-3xl">
          <div>
            <p>Henkilömäärä</p>
          </div>
          <div>
            <p>20-40</p>
          </div>
          <div>
            <p>713 (alv 24%)</p>
          </div>
          <div>
            <p>Henkilömäärä</p>
          </div>
          <div>
            <p>40-60</p>
          </div>
          <div>
            <p>899 (alv 24%)</p>
          </div>
        </div>
        <div className="max-w-2xl lg:mx-auto lg:text-center mx-auto">
          <p className="mt-10 text-gray-700 text-xl">
           Ylläolevat hinnat ovat alkaen hintoja
          </p>
          <br />
          <br />
        </div>
      </section>
      <section aria-labelledby="faq-heading" className="bg-white">
        <div className="bg-white">
          <br />
          <br />
          <div className="max-w-2xl lg:mx-auto lg:text-center mx-auto">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Puimala
            </h2>
          </div>
        </div>
        <div className="max-w-2xl lg:mx-auto lg:text-center mx-auto">
          <p className="mt-10 text-gray-700 text-xl">
          Hinta räätälöidään tilaisuuden mukaisesti riippuen siitä, onko Puimala juhlatilana vai juhlien jatkopaikkana.
          </p>
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="max-w-2xl lg:mx-auto lg:text-center mx-auto">
          <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
            Tervetuloa Jokirannan kartanoon - juhlia koko elämän kaarelle !
          </h2>
        </div>
      </section>
    </div>
  )
}
