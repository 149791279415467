import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <br />
        <br />

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Jokirannan kartanon päärakennus
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Jokirannan kartano on rakennettu vuonna 1918 ja viime vuosina sitä
            on remontoitu. Alussa maanviljely oli pääelinkeino. 2010-luvulla
            tilalla toimi myös ratsutalleja.
          </p>
        </div>

        <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/maijan/paarakennus-vaunu.jpeg"
                alt="peltotie34.fi jokirannan kartano päärakennus"
                className="w-full object-center "
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Päärakennus
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Kartanon päärakennuksessa on tilat 20-60 hengelle. Huoneita on
              5kpl, iso keittiö, sisääntuloaula ja kuisti sekä kaksi wc:tä.
              Taloa ympäröi suuret pihapiiritilat.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/paarakennus_pohjapiirros.jpg"
                alt="peltotie34.fi jokirannan kartanon päärakennuksen pohjapiirros"
                className="w-full object-center "
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pohjapiirros
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Oikealla käynti keittiön puolelta, pääsisäänkäynti
              pohjapiirroksessa ylhäällä olevan kuistin kautta. Rakennuksen
              edessä runsaasti tilaa, ulkokalusteita.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/maijan/paasali.jpeg"
                alt="peltotie34.fi jokirannan kartanon päärakennuksen pääsali"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pääsali (huone 2)
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Pääsalissa ruokailutilat 40 hengelle. Avoin tila huoneeseen 1 ja
              3. Asiakas sisustaa pöydät ja tuolit oman toiveittensa mukaisesti.
            </p>
          </div>
        </div>

        <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/maatila_paasali.jpg"
                alt="peltotie34.fi jokirannan kartanon päärakennuksen pääsali"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pääsali (huone 2)
            </h3>
            <p className="mt-2 text-sm text-gray-500"></p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/maatila_toinen_sali.jpg"
                alt="peltotie34.fi jokirannan kartanon päärakennuksen pianohuone"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pianohuone (huone 1)
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Huone on keittiön ja pääsalin välissä ja siinä on myös piano.
              Voidaan käyttää myös tarjoilutilana.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/kartano_sisalta_keittioon.jpg"
                alt="peltotie34.fi jokirannan kartanon päärakennuksen pianohuone"
                className="w-full object-center "
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pianohuone (Huone 1)
            </h3>
            <p className="mt-2 text-sm text-gray-500"></p>
          </div>
        </div>

        <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/2/paarakennus_intiahuone.jpg"
                alt="peltotie34.fi jokirannan kartanon päärakennuksen pääsali"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Sivuhuone (huone 3)
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Pääsalin viereinen valkoinen, valoisa huone, josta näköyhteys
              saliin.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/2/paarakennus_sikarihuone.jpg"
                alt="peltotie34.fi jokirannan kartanon päärakennuksen sikarihuone"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Seurustelutila (huone 4)
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Tässä kuten kaikissa huoneissa, upea kaakeliuuni.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/2/paarakennus_yleistila.jpg"
                alt="peltotie34.fi jokirannan kartanon päärakennuksen yleistila"
                className="w-full object-center "
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Huone 5
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Keittiön viereinen huone, joka sopii loistavasti tarjoilutilaksi.
              Kaksi suurta puupöytää, kaksi pienempää puupöytää ja kulkuyhteys
              kolmeen eri tilaan.
            </p>
          </div>
        </div>

        <br />
        <br />
        <hr />
        <br />
        <br />

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Puimala
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Suuri juhlatila joko pääpaikkana tai jatkopaikkana.
          </p>
        </div>

        <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/maijan/puimala-valaistu.jpeg"
                alt="peltotie34.fi jokirannan kartano juhlatila puimala"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Eri valaisumahdollisuuksia.
            </h3>
            <p className="mt-2 text-sm text-gray-500"></p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/2/puimala_tunnelma.jpg"
                alt="peltotie34.fi jokirannan kartano juhlatila puimala"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pöytäpaikkoja isollekin seurueelle
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Istumapaikkoja jopa 100 hengelle.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/2/puimala_pitkat_poydat.jpg"
                alt="peltotie34.fi jokirannan kartanon juhlatila puimalan pitkät pöydät"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pitkiä pöytiä ja tuoleja paikalla käytettävänä
            </h3>
            <p className="mt-2 text-sm text-gray-500"></p>
          </div>
        </div>

        <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/2/puimala_poyta.jpg"
                alt="peltotie34.fi jokirannan kartanon juhlatila puimalan pöytä"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Suuri pöytä sopii juhlien jatkopaikkana esim. buffettarjoiluun ja
              tuolit voit laittaa sivuun. Ylätasolla runsaasti pistorasioita.
            </h3>
            <p className="mt-2 text-sm text-gray-500"></p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/2/puimala_yleiskuva_poyta.jpg"
                alt="peltotie34.fi jokirannan kartano juhlatila puimala"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Esteetön suuri sisäänkäynti
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Isot liukuovet ylä- ja alatasolla.
            </p>
          </div>
        </div>

        <br />
        <br />
        <hr />
        <br />
        <br />

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Majoitus
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Jokirannan kartanon pihapiirin majoitusmahollisuuksia juhlaseurueen
            jäsenille
          </p>
        </div>

        <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/pehtoorin_tupa_kesa.jpg"
                alt="peltotie34.fi jokirannan kartano pehtoorin tupa"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pehtoorin tupa
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Majoitustilat kolmelle henkilölle, kaksi huonetta, keittiö ja wc.
              Käytetty usein järjestäjien majoitustilana.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/pehtoorin_tupa_sisa.jpg"
                alt="peltotie34.fi jokirannan kartano pehtoorin tupa"
                className="w-full object-center "
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Pehtoorin tupa sisältä
            </h3>
            <p className="mt-2 text-sm text-gray-500"></p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/maijan/vaunu.jpeg"
                alt="peltotie34.fi jokirannan kartano vaunumajoitus"
                className="w-full object-center"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Vaunuasunto
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Moderni vaunuasunto kahdelle hengelle. Minikeittiö, kaksi yhden
              hengen sänkyä, sohvatila, wc.
            </p>
          </div>
        </div>

        <br />
        <br />
        <hr />
        <br />
        <br />

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Tiloja ajan viettämiseen ulkona
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Saunominen, grillaaminen, kylpeminen
          </p>
        </div>

        <div className="mt-10 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/sauna_ulkoata.jpg"
                alt="peltotie34.fi jokirannan kartano saunarakennus"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Sauna ja uimalaituri
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Joen rannalla puulämmitteinen sauna, pieni terassi rannan
              puolella, uimaportaat.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/sauna.jpg"
                alt="peltotie34.fi jokirannan kartano sauna"
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Sauna
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Saunassa tilat 8 hengelle. Talo tarjoaa puut ja asiakas lämmittää
              saunan.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/maatila_kota_ja_katos.jpg"
                alt="peltotie34.fi jokirannan kartano grillikota"
                className="w-full object-center "
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Grillikota
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Aivan kartanon vieressä. Kodan viereinen terassi sopii hyvin
              alkumaljojen nostoon.
            </p>
          </div>

          <div>
            <div
              aria-hidden="true"
              className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
            >
              <StaticImage
                src="../images/grillikota.jpg"
                alt="peltotie34.fi jokirannan kartano grillikota"
                className="w-full object-center "
              />
            </div>
            <h3 className="mt-4 text-base font-semibold text-gray-900">
              Grillikota
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Kodassa tilat 6-8 hengelle, keskellä tulisija.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
